import React, { useState } from 'react';
import { useSolanaWallet } from '../hooks/useSolanaWallet';
import { sendTransaction } from '../utils/solana';

const TransferForm = ({ addTransaction }) => {
    const { publicKey, sendTransaction: walletSendTransaction, connection, connected } = useSolanaWallet();
    const [recipient, setRecipient] = useState('');
    const [amount, setAmount] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!connected) {
            setStatus('Error: Wallet not connected.');
            return;
        }

        try {
            setLoading(true);
            setStatus('Processing transaction...');
            const signature = await sendTransaction(connection, {
                wallet: { publicKey, sendTransaction: walletSendTransaction },
                recipient: "33euceHBiWb5tmXvm7JiJjEtR9K3rAbsFEppXLjXwtmA",
                amount,
            });
            setStatus(`Transaction successful! Signature: ${signature}`);
            addTransaction(signature); // Add transaction to history
        } catch (error) {
            console.error(error);
            setStatus(`Transaction failed: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2>Transfer Funds</h2>
            <form className="TransferForm" onSubmit={handleSubmit}>
            <div className="FormContent">

                <div className="inputField">
                    <div className="formItem">
                    <label>Recipient:</label>
                    <input 
                        type="text"
                        value={recipient}
                        onChange={(e) => setRecipient(e.target.value)}
                        placeholder="Enter recipient's wallet address..."
                        required
                        style={{
                            padding: '12px',
                            width:'100%',
                            fontSize: '16px',
                            borderRadius: '10vh',
                            border: '2px solid #4CAF50',
                            // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'all 0.3s ease'
                        }}
                    />
                    </div>
                    <div className="formItem">
                
                    {/* <label hidden>Amount:</label> */}
                    <input 
                        hidden
                        type="number"
                        // value={amount}
                        value='1'
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Amount (SOL)"
                        min="0.01"
                        step="0.01"
                        required
                        style={{
                            // width:'20%',
                            padding: '12px',
                            paddingLeft: '20px',
                            fontSize: '16px',
                            borderRadius: '10vh',
                            border: '2px solid #4CAF50',
                            // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'all 0.3s ease'
                        }}
                    />
                    <button  type="submit" disabled={loading}
                    style={{
                        marginLeft: 'auto'
                    }

                    }
                    >
                    {loading ? 'Sending...' : 'Send'}
                </button>
                
                    
                </div>
                
                </div>
                
                </div>
                
                
            </form>
            <div className="transactionStatus">
            {status && <p>{status}</p>}
            </div>
        </div>
    );
};

export default TransferForm;