import React from 'react';

const TransactionHistory = ({ transactions }) => {
    return (
        <div>
            <h2>Transaction History</h2>

            {transactions.length === 0 ? (
                <div className="monostyle">
                <p>No transactions yet.</p>
                </div>
            ) : (
                <ul>
                    {transactions.map((tx, index) => (
                        <li key={index}>
                                            <div className="transactionStatus">

                            <a
                                href={`https://explorer.solana.com/tx/${tx}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {tx}
                            </a>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default TransactionHistory;
