import React from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const WalletConnect = () => {
    return (
        <div className="WalletConnect">
            <div className="WalletButton">
            <WalletMultiButton style={{ margin: '15px', padding: '10px', borderRadius: '20px', boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)' }} />
            </div>
        </div>
    );
};

export default WalletConnect;