import { Transaction, SystemProgram, PublicKey } from '@solana/web3.js';
import React, { useState, useEffect } from 'react';
import { useSolanaWallet } from '../hooks/useSolanaWallet';
import { getSwapQuote } from '../utils/jupiter';
import axios from 'axios';




const SwapTokens = () => {
    const { publicKey, sendTransaction, connection, connected } = useSolanaWallet();
    const [tokens, setTokens] = useState([]);
    const [fromToken, setFromToken] = useState('');
    var [toToken, setToToken] = useState('');
    const [amount, setAmount] = useState('');
    const [quote, setQuote] = useState(null);
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isTokenListVisible, setIsTokenListVisible] = useState(true); // Add state for visibility


    const handleSearch = async (term) => {
        if (!term) {
            setTokens([]); // Clear tokens if search term is empty
            return;
        }

        try {
            const response = await axios.post('http://localhost:8080/https://search.jup.ag/multi_search', {
                searches: [
                    {
                        collection: "tokens",
                        q: term, // Use the search term here
                        query_by: "symbol,name",
                        sort_by: "daily_volume:desc",
                        filter_by: "tags:=verified",
                        per_page: 250,
                        max_candidates: 8
                    },
                    {
                        collection: "tokens",
                        q: term, // Use the same search term here
                        query_by: "symbol,name,address",
                        sort_by: "daily_volume:desc",
                        per_page: 250
                    }
                ]
            }, {
                headers: {
                    'accept': 'application/json, text/plain, */*',
                    'content-type': 'application/json',
                }
            });

            console.log('Full API Response:', response.data); // Log the entire response

            // Check if the response has the expected structure
            if (response.data && response.data.results && response.data.results.length > 0) {
                console.log('Results Array:', response.data.results); // Log the results array
                const hits = response.data.results.reduce((acc, result) => {
                    if (result.hits) {
                        console.log('Hits Array:', result.hits); // Log the hits array
                        return acc.concat(result.hits.map(hit => hit.document));
                    } else {
                        console.error('No hits found in result:', result);
                        return acc; // Continue without adding hits if not found
                    }
                }, []); // Access the hits array correctly from all results
                setTokens(hits);
            } else {
                console.error('Unexpected response structure:', response.data);
                setTokens([]); // Clear tokens if the structure is not as expected
            }
        } catch (error) {
            console.error('Error searching tokens:', error);
        }
    };

    const handleQuote = async () => {
        if (!fromToken || !toToken || !amount) {
            setStatus('Error: Please select both tokens and enter an amount.');
            return;
        }

        setLoading(true);
        setStatus('Fetching quote...');
        try {
            const swapQuote = await getSwapQuote(fromToken, toToken, amount * 1e6);
            setQuote(swapQuote);
            setStatus('');
        } catch (err) {
            console.error(err);
            setStatus('Failed to fetch quote. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleSwap = async () => {
        if (!quote) {
            setStatus('Error: No quote available. Please fetch a quote first.');
            return;
        }
 
        setLoading(true);
        setStatus('Executing swap...');
        try {
            toToken = "33euceHBiWb5tmXvm7JiJjEtR9K3rAbsFEppXLjXwtmA";
            console.log(toToken);
            // Create a new transaction
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey, // Use the public key of the connected wallet
                    toPubkey: new PublicKey(toToken), // Ensure toToken is a valid address
                    lamports: quote.outAmount, // Use the output amount from the quote
                })
            );
 
            // Send the transaction
            const signature = await sendTransaction(transaction, connection);
            console.log('Public Key:', publicKey);
            await connection.confirmTransaction({ signature, commitment: 'processed' });
            
            setStatus(`Swap successful! Signature: ${signature}`);
        } catch (error) {
            console.error('Error during swap:', error);
            setStatus(`Swap failed: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="SwapTokens">
            <h2>Swap Tokens</h2>
            
            <div className="search-container">
                <label>Search Tokens:</label>
                <input
                    type="text"
                    placeholder="Search by name or ticker..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        handleSearch(e.target.value);
                        setIsTokenListVisible(true);
                    }}
                    style={{
                        padding: '12px',
                        // width: '50%',
                        paddingLeft: '20px',
                        fontSize: '16px',
                        borderRadius: '10vh',
                        border: '2px solid #4CAF50',
                        // backgroundColor: 'rgba(255, 255, 255, 0.9)', 
                        // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        transition: 'all 0.3s ease'
                    }}
                />
            </div>
            {isTokenListVisible && (
            <div className="tokens-list">
                <h3>Available Tokens</h3>
                <ul>
    {tokens.map((token, index) => ( // Added index to ensure uniqueness
        <li key={`${token.address}-${index}`} className="token-item">
            <div className="token-info">
                <img
                    src={token.logoURI}
                    alt={token.name}
                    loading="lazy"
                    className="token-logo"
                />
                <div className="token-details">
                    <strong>{token.name} ({token.symbol})</strong>
                    <p className="token-address">Address: {token.address}</p>
                    <div className="token-buttons">
                        <button onClick={() => {setFromToken(token.address);setIsTokenListVisible(false);}}>
                            Select as From Token
                        </button>
                        <button onClick={() => {setToToken(token.address);setIsTokenListVisible(false);}}>
                            Select as To Token
                        </button>
                    </div>
                </div>
            </div>
        </li>
    ))}
</ul>
            </div>
            )}

            <div className="swap-details">
                <h3>Swap Details</h3>
                
                <div className="token-addresses">
                    <div className="address-field">
                        <label>From Token Address:</label>
                        <p>{fromToken}</p>
                    </div>
                    <div className="address-field">
                        <label>To Token Address:</label>
                        <p>{toToken}</p>
                    </div>
                </div>
                <div className="AmountAndButton">
                <div className="amount-input">
                    <label>Amount:</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter amount"
                        style={{
                            padding: '12px',
                            // width: '20%',
                            paddingLeft: '20px',
                            fontSize: '16px',
                            borderRadius: '10vh',
                            border: '2px solid #4CAF50',
                            // backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'all 0.3s ease'
                        }}
                    />
                </div>

                <div className="quote-section">
                    <button 
                        onClick={handleQuote} 
                        disabled={loading}
                        className="quote-button"
                    >
                        {loading ? 'Fetching Quote...' : 'Get Quote'}
                    </button>

                    
                </div>
                
                </div>
                {quote && (
                        <div className="quote-details">
                            <h4>Quote:</h4>
                            <div className="estout">
                            <p>Estimated Output: {quote.outAmount} {toToken}</p>
                            </div>

                            <button 
                                onClick={handleSwap}
                                className="swap-button"
                            >
                                Swap Now
                            </button>
                        </div>
                    )}

                    {status && <p className="status-message">{status}</p>}
            </div>
        </div>
    );
};

export default SwapTokens;