import axios from 'axios';

const JUPITER_API_BASE = 'https://quote-api.jup.ag/v6';

export const getSwapQuote = async (inputMint, outputMint, amount) => {
    try {
        const response = await axios.get(`${JUPITER_API_BASE}/quote`, {
            params: {
                inputMint,
                outputMint,
                amount,
                autoSlippage: true, // 1% slippage
            },
        });
        return response.data;
    } catch (err) {
        throw new Error(`Failed to fetch swap quote: ${err.message}`);
    }
};