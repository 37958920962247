import React, { useState } from 'react';
import { WalletConnectionProvider } from './hooks/useSolanaWallet';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import WalletConnect from './components/WalletConnect';
import TransferForm from './components/TransferForm';
import SwapTokens from './components/SwapTokens';
import TransactionHistory from './components/TransactionHistory';

function App() {
    const [transactions, setTransactions] = useState([]);

    const addTransaction = (transaction) => {
        setTransactions((prevTransactions) => [...prevTransactions, transaction]);
    };

    return (
        <WalletConnectionProvider>
            <WalletModalProvider>
                <div className="App">
                    <div className="topTitle">
                    <h1>$goober Airdrop</h1>
                    <WalletConnect />
                    </div>
                    <TransferForm addTransaction={addTransaction} />
                    <SwapTokens />
                    <TransactionHistory transactions={transactions} />
                </div>
            </WalletModalProvider>
        </WalletConnectionProvider>
    );
}

export default App;