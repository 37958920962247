import { useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { Connection, clusterApiUrl } from '@solana/web3.js';
import { useMemo } from 'react';
import '@solana/wallet-adapter-react-ui/styles.css';

export const useSolanaWallet = () => {
    const network = WalletAdapterNetwork.Mainnet; // Change to 'Mainnet' for production
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const wallets = useMemo(() => [new PhantomWalletAdapter()], []);

    const { publicKey, sendTransaction, connected } = useWallet();
    const connection = new Connection(endpoint);

    return {
        publicKey,
        sendTransaction,
        connected,
        connection,
        wallets,
        endpoint,
    };
};

export const WalletConnectionProvider = ({ children }) => {
    const wallets = useMemo(() => [new PhantomWalletAdapter()], []);
    return (
        <WalletProvider wallets={wallets} autoConnect>
            {children}
        </WalletProvider>
    );
};